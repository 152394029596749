@import "../../../../style/focuswin.variables.base.scss";

$marker-s0-color: #6baafc;
$marker-s1-color: hsl(110, 67%, 50%);
$marker-s2-color: #ff9c39;
$marker-s3-color: #D1A4FF;
$marker-s4-color: #fd5d53;
$marker-s5-color: #777777;

// 충전소 리스트
.fw-maps-overlay-list-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 280px;
    height: 100%;
    transition: all .4s;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 
    0 1px 3px rgba(0, 0, 0, 0.08);
    &.passive {
        transform: translateX(-100%);
        .dx-list-search {
            opacity: 0;
            visibility: hidden;
        }
    }

    .fw-maps-overlay-list-title {
        font-size: 16px;
        font-weight: 600;
        padding: 15px 20px 10px;
    }

    .dx-texteditor.dx-editor-filled .dx-placeholder::before, .dx-texteditor.dx-editor-filled .dx-texteditor-input, .dx-texteditor.dx-editor-outlined .dx-placeholder::before, .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
        padding-inline-start: 32px;
    }

    // 검색
    .dx-list-search {
        width: calc(100% - 28px);
        padding: 4px;
        margin: 14px 0 0 14px;
        border-radius: 8px;
        background-color: #ffffff;
        transition: all .4s;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
        &.dx-state-hover {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 6px 0px;
        }
        &.dx-state-focused {
            box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 6px 0px;
        }

        .dx-texteditor-container {
            border-radius: 8px;
        }
    }

    // 토글 버튼튼
    .fw-maps-overlay-toggle-button {
        position: absolute;
        top: 50%;
        right: 0;
        width: 20px;
        min-width: 20px;
        height: 42px;
        border-radius: 0 4px 4px 0 !important;
        transform: translate(100%, -50%);
        background-color: #fafafa;
        border: 1px solid #EEEEEE;
        border-right: none;
        z-index: -150;
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
        0 1px 3px rgba(0, 0, 0, 0.08) !important;
        &.dx-state-hover {
            background-color: #eeeeee;
        }
        .dx-button-content {
            padding: 0;
        }
    };

    .dx-scrollable-wrapper {
        padding-top: 5px;
    }

    .dx-item.dx-list-item {
        pointer-events: none;
        background-color: transparent !important;
        .dx-inkripple-wave {
            display: none;
        }
        &.dx-state-hover {
            .fw-maps-overlay-list-item {
                background-color: #e9e9e9 !important;
            }           
        }
        &.dx-state-active {
            .fw-maps-overlay-list-item {
                background-color: #dddddd !important;
            }           
        }        
        &.dx-list-item-selected {
            &.dx-state-hover {
                background-color: #fff;
            }
        }
        .dx-item-content.dx-list-item-content {
            padding: 0;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 10px;
            .fw-maps-overlay-list-item-container {
                width: 255px;
                padding-bottom: 10px;
            }
            .fw-maps-overlay-list-item {
                display: flex;
                flex-direction: column;
                align-items: start;
                padding: 20px 20px 10px;
                pointer-events: all;
                transition: all .2s;
                border-radius: 8px;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 4px 8px rgba(0, 0, 0, 0.08);                
                background-color: #ffffff;
                .fw-list-item-header {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;
                    color: #000000;
                    padding-bottom: 5px;
                    .fw-list-item-main-img {
                        width: 18px;
                        height: 18px;
                        padding: 3px 3px 3px 3px;
                        margin-right: 5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: $marker-s5-color;
                        border-radius: 3px;
                        border: 1px solid transparent;
                        &.Available {
                            background-color: $marker-s0-color;
                        }
                        &.Finishing {
                            background-color: $marker-s1-color;
                        }
                        &.Preparing {
                            background-color: $marker-s2-color;
                        }
                        &.Charging {
                            background-color: $marker-s3-color;
                        }
                        &.SuspendedEVSE,&.SuspendedEV,&.Reserved,&.Unavailable,&.Faulted {
                            background-color: $marker-s4-color;
                        }
                    }
                    &.Available {
                        color: $marker-s0-color;
                    }
                    &.Finishing {
                        color: $marker-s1-color;
                    }
                    &.Preparing {
                        color: $marker-s2-color;
                    }
                    &.Charging {
                        color: $marker-s3-color;
                    }
                    &.SuspendedEVSE,&.SuspendedEV,&.Reserved,&.Unavailable,&.Faulted {
                        color: $marker-s4-color;
                    }
                }

                .fw-list-item-detail {
                    display: flex;
                    flex-direction: column;      
                    color: rgb(92, 92, 92);             
                    .fw-list-item-detail-list {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        align-items: start;
                        line-height: 1.5;
                        padding: 5px 0;
                        div {
                            flex-shrink: 0;
                            width: 20px;
                            margin-top: 2px;
                            margin-right: 5px;
                            text-align: center;
                        }
                        span {
                            text-wrap: wrap;
                        }
                    }
                }
            }            
        }
    }
}

// 충전기 리스트
.fw-maps-overlay-detail-container {
    position: absolute;
    width: 300px;
    height: 100%;
    top: 0;
    left: 280px;
    z-index: 50;
    transform: translateX(0);  
    background-color: rgba(255, 255, 255, .9);
    transition: all .6s;

    &.passive {
        transform: translateX(-250%);  
    }

    .fw-maps-overlay-detail-close-button {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 20px;
        min-width: 20px;
        height: 100%;
        font-size: 14px;
        transition: all .4s;
        .dx-button-content {
            padding: 0;
        }
    }

    .fw-maps-overlay-detail {
        position: relative;
        padding-top: 40px;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            background-color: rgb(226, 226, 226);
        }
        &::-webkit-scrollbar-thumb { 
            background-color: rgb(199, 199, 199);
        }
        &::-webkit-scrollbar-button {
            display: none;
        }

        .fw-maps-overlay-detail-none-mes {
            padding: 15px;
            text-align: center;
            color: #808080;
        }

        .fw-maps-charger-list {
            display: flex;
            flex-direction: row;
            align-items: start;
            width: 100%;
            padding: 5px 10px;

            .fw-maps-charger-list-item {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-wrap: nowrap;
                padding: 10px 10px 10px 10px;
                box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1), 
                0 4px 8px rgba(0, 0, 0, 0.08);            
                background-color: #fff;
                cursor: pointer;        
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: center;
                color: $marker-s5-color;
                &:hover {
                    background-color: #f7f7f7;
                }
                &.Available {
                    color: $marker-s0-color;
                    .fw-maps-charger-header svg rect {
                        fill: $marker-s0-color;
                    }
                }
                &.Finishing {
                    color: $marker-s1-color;
                    .fw-maps-charger-header svg rect {
                        fill: $marker-s1-color;
                    }
                }
                &.Preparing {
                    color: $marker-s2-color;
                    .fw-maps-charger-header svg rect {
                        fill: $marker-s2-color;
                    }               
                }     
                &.Charging {
                    color: $marker-s3-color;
                    .fw-maps-charger-header svg rect {
                        fill: $marker-s3-color;
                    }               
                }     
                &.SuspendedEVSE, &.SuspendedEV, &.Reserved, &.Unavailable, &.Faulted {
                    color: $marker-s4-color;
                    .fw-maps-charger-header svg rect {
                        fill: $marker-s4-color;
                    }               
                }                                                                            
                .fw-maps-charger-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                        width: 35px;
                        height: 35px;
                        rect {
                            fill: $marker-s5-color;
                        }
                    }
                }
                .fw-maps-charger-detail {
                    display: flex;
                    flex-direction: column;
                    align-items: start;
                    margin-left: 10px;
                    .fw-maps-charger-name {
                        color: #313131;
                    }                    
                }
            }
        }
    }
}