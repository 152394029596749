// 테마 강조 색상
$primary-accent-color: var(--primary-accent-color);
$secondary-accent-color: var(--secondary-accent-color);

// 기본 색상
$base-text-color: var(--base-text-color);
$base-icon-color: var(--base-icon-color);
$base-bck-color: var(--base-bck-color);
$base-border-color: var(--base-border-color);

// 모드 색상
$primary-depth-color: var(--primary-depth-color);
$secondary-depth-color: var(--secondary-depth-color);
$tertiary-depth-color: var(--tertiary-depth-color);
$mode-accent-color: var(--mode-accent-color);

$base-border-radius: 4px;