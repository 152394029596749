@import '../../../style/focuswin.variables.base.scss';

.fw-canvas-container {
    padding: 8px;
    display: flex;

    .fw-palette-tools-container {
        justify-content: space-between;
        padding-bottom: 6px;

        .fw-palette-wrapper .dx-widget.dx-button {
            border-radius: 2px;

            &:last-child {
                border-left: none;
            }
        }
    }

    .fw-canvas {
        border: 1px solid rgb(150, 150, 150, .5) !important;
    }
}