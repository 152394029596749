@import "../../../style/focuswin.variables.base.scss";

.account-container {
  width: 100%;
  height: 100%;
  background-color: #F9FAFE;
  position: relative;
  overflow: hidden;
  overflow: hidden;

  .accont-card {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--secondary-depth-color);
    box-shadow: 0 3px 9px rgba(0, 0, 0, .05);
    border-radius: 32px;
    z-index: 10;
    overflow: hidden;
    width: 350px;
  }

  .account-footer {
    width: 100%;
    font-size: .9rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #98a6ad;
    padding: 19px 24px 20px;
    text-align: center;
    z-index: 15;
  }
};

.card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  background-color: var(--primary-accent-color);
  border-radius: 8px 8px 0 0;

  img {
    width: 200px;
    margin-bottom: 30px;
  }
}

.card-body-header-title {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
};

.card-body-header-detail {
  font-size: 14.4px;
  color: rgba(255, 255, 255, .87);
  margin-bottom: 10px;
};

.card-body {
  padding: 0 36px 36px;
  position: relative;

  .login-form {
    padding: 10px 0;

    .account-panel-form {
      padding: 0 0 40px;
    }
  }

  .first-btn {
    margin-top: 32px;
    height: 38px;

    .dx-button-text {
      font-size: 14px;
    }
  }

  .account-btn {
    margin-top: 10px;
    height: 38px;
  }

  .link {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, .57);

    .dx-button {
      padding: 0;
      background: transparent;
      box-shadow: none;
      &.dx-state-hover .dx-button-text {
        color: #57cac3;
      }
      .dx-button-text {
        text-decoration: underline;
        color: #33a79f;
        transition: all .4s;
      }
    }
  }

  .dx-texteditor {
    border-radius: 32px;
  }

  .fw-input-label {
    display: none;
  }

  .dx-texteditor.dx-editor-outlined::before {
    left: 12px;
    width: calc(100% - 24px);
  }

  .dx-item .dx-item-content .dx-field-item-content {
    font-size: 14px;

    .dx-label span {
      color: #6C757D;
      font-weight: 600;
      top: -26px;
    }

    .dx-label-before,
    .dx-label-after {
      border-top-color: #dee2e6;
      border-bottom-color: #dee2e6;
      border-inline-start-color: #dee2e6;
      border-inline-end-color: #dee2e6;
    }

    .dx-invalid {

      .dx-label-before,
      .dx-label-after {
        border-top-color: #fa5c7c;
        border-bottom-color: #fa5c7c;
        border-inline-start-color: #fa5c7c;
        border-inline-end-color: #fa5c7c;
      }
    }
  }
}

.dx-button.dx-button-mode-contained.dx-button-default {
  &.kakao {
    background-color: #FEE500;
    color: rgba(0, 0, 0, .85);
    &.dx-state-hover {
      background-color: #f1db11;
    }
  }
  &.naver {
    background-color: #03C75A;
    color: #fff;
    &.dx-state-hover {
      background-color: #13bd5f;
    }
  }
}


.fw-button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  i {
    margin-right: 8px;
    width: 18px;
    height: 18px;
  }
}

.sns-login-devider {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  z-index: 10;
  color: rgba(0, 0, 0, .57);
}

.screen-x-small {
  .accont-card {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    border-radius: 0;

    .card-header {
      padding: 80px 24px;
    }
  }
}

.card-body-wave {
  background-image: url("data:image/svg+xml,%3Csvg width='800' height='99' viewBox='0 0 800 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M799.998 60.5001C644.5 60.5001 595.051 -0.124807 394.5 0.000193033C194.5 0.000193033 144.5 60.5001 0 60.5001V99H800C800 99 799.998 75.0001 799.998 60.5001Z' fill='%2354C2BA'/%3E%3C/svg%3E%0A");
  position: absolute;
  width: 3200px;
  top: -5px;
  left: 0;
  height: 99px;
  transform: translate3d(0, 0, 0) rotateX(180deg);
  animation: wave 7s ease infinite;

  &:nth-child(2) {
    top: -30px;
    animation: swell 4s ease infinite;
    opacity: .5;
  }
}

@keyframes wave {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: -800px;
  }
}

@keyframes swell {

  0%,
  100% {
    transform: translate(0, -12px);
  }

  50% {
    transform: translate(0, 0);
  }
}