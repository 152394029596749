@import "../../../style/focuswin.variables.base.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar-items-container {
    height: 50px;
  }
  .dx-toolbar .dx-toolbar-item.menu-button>.dx-toolbar-item-content .dx-icon {
    color: $base-icon-color;
  }
  .header-toolbar.dx-toolbar {
    padding: 5px;
    background-color: $primary-depth-color;
  }

}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 20px;
}

.screen-x-small .dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 10px;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
  .dx-button {
    height: auto;
    transition: all .4s;

    &:hover {
      background: none;
      opacity: .6;
    }

    &:active {
      background: none;
    }
    .dx-button-content {
      padding: 0;
      font-size: 16px;
    }
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button>.dx-button-content {
    padding: 3px;
  }
}

.dx-toolbar-after {
  .dx-toolbar-item-content {
    .authorization {
      margin-right: 5px;
      transition: all .4s;
      background-color: #fafbfd;
      border: 1px solid #f1f3fa;
      &.active {
        background-color: $secondary-accent-color;        
      }
      &:hover {
        filter: grayscale(2);
      }
    }
  }
}

.dx-overlay-content:not(.dx-popup-fullscreen-width) {
  .dx-toolbar.dx-popup-title {
      color: #6C757D;
      .dx-item-content.dx-toolbar-item-content > div {
        font-weight: 700;
      }
      .dx-button-content {
        .dx-icon {
          color: #6C757D;
        }
      }
  }
}

.fw-content-header {
  font-size: 18px;
  font-weight: 700;
  padding: 20px 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .header-before {
    display: flex;
    flex-direction: row;
    align-items: center;
    i {
      width: 20px;
      height: 20px;
      background-size: contain;
      margin-right: 10px;
    }
  }
}