@import '../../../style/focuswin.variables.base.scss';

.dx-popup-content .dx-datagrid-header-panel {
  padding: 5px;
}

.dx-datagrid-header-panel {
  padding: 0 0 5px;
  .fw-grid-header {
    font-size: 14px;
    font-weight: 700;
    color: var(--primary-depth-color);
    background-color: #3b4149;
    border-radius: 4px;
    padding: 0 10px;
    min-width: 150px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .dx-item-content.dx-toolbar-item-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
    .fw-tooltip-button-wrapper, .dx-editor-outlined, .dx-editor-filled, .dx-checkbox-container {
      &.dx-texteditor:not(.dx-end-datebox, .dx-start-datebox) {
        background-color: #fff;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .24);
      }
      &:not(:first-child) {
        margin-left: 5px;
        &.dx-checkbox-container {
          padding: 0 5px;
        }
      }
      .dx-button {
        height: 32px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, .25);
      }
    }
  }

  .dx-toolbar-items-container {
    .dx-state-disabled .dx-buttongroup.dx-buttongroup-mode-contained {
      background-color: var(--primary-depth-color);
      color: var(--base-text-color);
      opacity: .6;
      box-shadow: none;
    }
    .dx-button.dx-button-mode-contained, .dx-buttongroup.dx-buttongroup-mode-contained {
      background-color: var(--primary-depth-color);
      color: var(--base-text-color);
      margin-right: 0;

      .dx-icon {
        color: var(--base-text-color);
      }

      &:hover, &:active {
        background-color: var(--base-border-color);
      }
      &.dx-state-disabled {
        background-color: var(--primary-depth-color);
        color: var(--base-text-color);
        opacity: .8;
        .dx-icon {
          color: var(--base-text-color);
          opacity: .5;
        }
      }
    }
  }
}

.dx-toolbar-menu-section {
  .fw-grid-header-date-search-panel {
    .dx-checkbox {
      padding-left: 10px;
    }
  }
  .dx-item-content.dx-list-item-content {
    .dx-editor-filled, .fw-tooltip-button-wrapper {
      width: 100% !important;
      &:not(:first-child) {
        margin-top: 10px;
      }
    }
  }
  .fw-grid-before-box {
    width: 100% !important;
  }
  .dx-datagrid-search-panel {
    width: 100% !important;
    min-width: 160px;
    margin: 0;
  }
  .dx-button.dx-button-mode-contained {
    width: 100%;
  }
}

// 모니터링 - 대쉬보드 - 충전기별 현황 그룹 열
.fw-custom-gruop-moni-dash {
  display: flex;
  justify-content: left;
  align-items: center;
  .fw-group-title {
    margin-right: 5px;
    padding: 4px 8px;
    color: #fff;
    background-color: rgb(108, 159, 218);
    border-radius: 8px;
    &.erro {
      background-color: rgb(218 108 108);
    }
    &.good {
      background-color: rgb(108 218 172);
    }
    &.none {
      background-color: rgb(143, 143, 143);
    }
  }
  i {
    font-size: 16px;
    &.dx-icon-tips {
      color: rgb(243, 243, 100);
    }
    &.dx-icon-money {
      color: rgb(240, 240, 56);
    }    
  }
  .fw-group-detail {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-right: 5px;
    b {
      margin: 0 5px;
      text-shadow: 
      -.5px 0 0 rgba(0, 0, 0, .54),
    }
  }
}