.side-nav-inner-toolbar {
  width: 100%;
}

#navigation-header {
  height: 60px;
  background-color: rgb(50, 50, 50);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  .dx-toolbar-items-container {
    height: 60px;
    .dx-item.dx-toolbar-item.dx-toolbar-button.menu-button {
      width: 60px;
      padding: 0;
      .dx-item-content.dx-toolbar-item-content {
        width: 60px;
        display: flex;
        justify-content: center;
        .dx-button {
          transition: all .4s;
          .dx-icon {
            color: var(--primary-depth-color);
            font-size: 24px;
          }
          &:hover {
            filter: opacity(.8);
          }
        }
      }
    }
    .dx-toolbar-before {
      width: 100%;
    }
    .comp-imag {
      height: 28px;
      filter: brightness(0) saturate(100%) invert(1);
      transition: all .4s;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }

  .screen-x-small & {
    padding-left: 20px;
  }

  .dx-theme-generic & {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}