@import "../../../style/focuswin.variables.base.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 250px !important;

  .menu-container {
    min-height: 100%;
    display: flex;
    flex: 1;
    background-color: rgb(50, 50, 50);

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        padding: 15px 16px;
        padding-left: 0;
        flex-direction: row-reverse;
        color: #fbfbfb;
        display: flex;
        align-items: center;
        .dx-icon {
          width: 60px !important;
          background-position: 50%;
          filter: brightness(0) saturate(100%) invert(.9);
          margin: 0 !important;
          
        }
      }
      
      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
        position: relative;
        .dx-treeview-node.dx-state-selected {
          &::before {
            content: "";
            position: absolute;
            width: 1%;
            height: 100%;
            top:0;
            left: 99%;
            background-color: $secondary-accent-color;
          }
        }
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }


      // ## 트리 아이템 레벨별 커스텀
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          border-bottom: 1px solid rgba(255, 255, 255, .1);
        }
  
        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding-left: 60px;
        }

        &[aria-level='3'] .dx-treeview-item-content {
          font-weight: normal;
          padding-left: 80px;
        }

        &[aria-level='4'] .dx-treeview-item-content {
          font-weight: normal;
          padding-left: 100px;
        }        
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          .dx-treeview-item {
            transition: all .4s;
          }
          &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
            background-color: rgb(207, 208, 235, .1);
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $secondary-accent-color;
          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover {
            background-color: rgb(60, 60, 60);
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
      }
    }
    // ##
  }
}

.dx-treeview-toggle-item-visibility {
  color: rgba(255, 255, 255, 0.54);
}

.dx-scrollable-content > ul.dx-treeview-node-container {
  padding-bottom: 100px;
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../../../utils/patches.scss";
  @include menu-pre-init-patch;
}