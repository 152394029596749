@import "../focuswin//style/focuswin.variables.base.scss";

@mixin menu-pre-init-patch {
    $menuMinSize: 60px;
  
    .dx-drawer-content {
      padding-left: $menuMinSize;
    }
  
    .dx-drawer-panel-content.dx-overlay::before {
      content: "";
      width: $menuMinSize;
      height: 100%;
      display: inline-block;
      position: absolute;
      background-color: $base-bck-color;
    }
  
    .dx-overlay-content {
      width: $menuMinSize !important;
    }
}
