@import "../../../style/focuswin.variables.base.scss";

.imag-info {
  padding: 0 15px;
  .comp-imag {
    height: 10px;
  }
};

.text-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: #98a6ad;
  line-height: 1;
  .comp-info {
    font-size: 14.4px;
    font-weight: 600;
  }
  .user-info {
    padding-top: 4px;
    font-size: 12px;
  }
};

.user-panel {
  padding-right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }
  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }
  .dx-menu-item .dx-menu-item-content {
      padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}

.popup-content {
  .preference-row {
    width: 100%;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 16px;
    &:first-child {
      padding-top: 16px;
    }
    .row-title {
      width: 140px;
    }
    .row-content {
      &.switch-content {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 25px;
          background-color: $mode-accent-color;
          opacity: 0.5;
        }
      }
      .color-palette {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin: 0 4px;
        border-radius: 50%;
        transition: all 300ms;
        &.active {
          transform: scale(1.3);
        }
      }

      .dx-button {
        transition: all 300ms;
        margin-top: 0;
        opacity: 0.5;

        &:first-child {
          margin-right: 5px;
        }
        &.active {
          background-color: $mode-accent-color;
          opacity: 1;
        }
      }
    }
  }
}