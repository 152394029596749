@import '../../../style/focuswin.variables.base.scss';

.fw-chart-header {
    height: 53px;
    display: flex;
    align-items: center;
    span {
        font-size: 14px;
        font-weight: 700;
        color: var(--primary-depth-color);
        background-color: #313A46;
        padding: 10px;        
    }
};

.fw-chart-body {
    background-color: var(--primary-depth-color);
    border: 1px solid #e0e0e0;
    padding: 10px;
}