@import "../../../style/focuswin.variables.base.scss";

.account-footer {
  width: 100%;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  color: #98a6ad;
  padding: 19px 24px 20px;
  text-align: center;
  z-index: 15;
}

.screen-x-small {
  .accont-card {
    position: relative;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    .card-header {
      padding: 80px 24px;
    }
  }
}
