@import '../../../style/focuswin.variables.base.scss';

$marker-s0-color: #6baafc;
$marker-s1-color: hsl(110, 67%, 50%);
$marker-s2-color: #ff9c39;
$marker-s3-color: #D1A4FF;
$marker-s4-color: #fd5d53;
$marker-s5-color: #777777;

.fw-map {
    width: 100%;
    height: calc(100vh - 60px - 57px - 74px - 30px);
}

.fw-map-overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .4s;
    &.sel {
        filter: contrast(1.2);
        transform: scale(1.15);
        transform-origin: bottom center;
    }

    .fw-map-overlay-marker, .fw-map-overlay-textbox {
        display: flex;
        justify-content: center;
        align-items: center;
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            border-style: solid;
        }        
    }
    .fw-map-overlay-textbox {
        background-color: #fff;
    }
    .fw-map-overlay-marker {
        border-radius: 50%;
    }
    .marker-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
    }
    &.station {
        .fw-map-overlay-textbox {
            padding: 5px 15px;
            margin-bottom: 10px;
            border-radius: 8px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
            font-weight: bold;
            font-size: 13px;
            color: rgba(51, 51, 51, 0.87);
            &::after {
                bottom: 45px;
                border-width: 10px;
                border-color: #ffffff transparent transparent  transparent;
            }
            &::before {
                z-index: -1;
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 43px;
                border-width: 10px;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.05) transparent transparent  transparent;
            }
        }
        .fw-map-overlay-marker {
            width: 50px;
            height: 50px;
            background-color: $marker-s5-color;
            &::after {
                bottom: -28px;
                border-width: 18px;
                border-color: $marker-s5-color transparent transparent  transparent;
            }
            &.Available {
                background-color: $marker-s0-color;
                &::after {
                    border-color: $marker-s0-color transparent transparent transparent;
                }
            }
            &.Finishing {
                background-color: $marker-s1-color;
                &::after {
                    border-color: $marker-s1-color transparent transparent transparent;
                }
            }
            &.Preparing {
                background-color: $marker-s2-color;
                &::after {
                    border-color: $marker-s2-color transparent transparent transparent;
                }
            }
            &.Charging {
                background-color: $marker-s3-color;
                &::after {
                    border-color: $marker-s3-color transparent transparent transparent;
                }
            }
            &.SuspendedEVSE,&.SuspendedEV,&.Reserved,&.Unavailable,&.Faulted {
                background-color: $marker-s4-color;
                &::after {
                    border-color: $marker-s4-color transparent transparent transparent;
                }
            }
        }
        .marker-icon {
            width: 35px;
            height: 35px;
            background-color: #fff;
            svg { rect, path { fill: $marker-s5-color; } }
            &.Available {
                svg { rect, path { fill: $marker-s0-color; } }
            }
            &.Finishing {
                svg { rect, path { fill: $marker-s1-color; } }
            }
            &.Preparing {
                svg { rect, path { fill: $marker-s2-color; } }
            }
            &.Charging {
                svg { rect, path { fill: $marker-s3-color; } }
            }
            &.SuspendedEVSE,&.SuspendedEV,&.Reserved,&.Unavailable,&.Faulted {
                svg { rect, path { fill: $marker-s4-color; } }
            }
        }
    }

    &.charger.sel {
        .fw-map-overlay-textbox {
            opacity: 1;
            visibility: visible;
        }   
    }
    &.charger {
        .fw-map-overlay-textbox {
            padding: 3px 10px;
            margin-bottom: 10px;
            border-radius: 8px;
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
            font-weight: bold;
            font-size: 11px;
            color: rgba(255, 255, 255, 0.87);
            background-color: $marker-s5-color;
            &::after {
                bottom: 30px;
                border-width: 10px;
                border-color: $marker-s5-color transparent transparent  transparent;
            }
            &::before {
                z-index: -1;
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 30px;
                border-width: 10px;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.05) transparent transparent  transparent;
            }            
            opacity: 0;
            visibility: hidden;
        }
        .fw-map-overlay-marker {
            .marker-icon {
                svg {
                    width: 35px;
                    height: 35px;
                    rect {
                        fill: $marker-s5-color;
                    }
                }
            }
            &::after {
                bottom: -15px;
                border-width: 10px;
                border-color: $marker-s5-color transparent transparent  transparent;
            }
        }
    }

    &.Available.charger {
        .fw-map-overlay-textbox {
            background-color: $marker-s0-color;
            &::after {
                border-color: $marker-s0-color transparent transparent  transparent;
            }
        }        
        .fw-map-overlay-marker {
            .marker-icon svg rect {
                fill: $marker-s0-color; // 이용가능
            }
            &::after {
                border-color: $marker-s0-color transparent transparent  transparent;
            }
        }        
    }

    &.Finishing.charger {
        .fw-map-overlay-textbox {
            background-color: $marker-s1-color;
            &::after {
                border-color: $marker-s1-color transparent transparent  transparent;
            }
        }
        .fw-map-overlay-marker {
            .marker-icon svg rect {
                fill: $marker-s1-color; // 충전완료
            }
            &::after {
                border-color: $marker-s1-color transparent transparent  transparent;
            }
        }        
    }

    &.Preparing.charger {
        .fw-map-overlay-textbox {
            background-color: $marker-s2-color;
            &::after {
                border-color: $marker-s2-color transparent transparent  transparent;
            }
        }        
        .fw-map-overlay-marker {
            .marker-icon svg rect {
                fill: $marker-s2-color; // 준비중
            }
            &::after {
                border-color: $marker-s2-color transparent transparent  transparent;
            }
        }        
    }

    &.Charging.charger {
        .fw-map-overlay-textbox {
            background-color: $marker-s3-color;
            &::after {
                border-color: $marker-s3-color transparent transparent  transparent;
            }
        }        
        .fw-map-overlay-marker {
            .marker-icon svg rect {
                fill: $marker-s3-color; // 충전중
            }
            &::after {
                border-color: $marker-s3-color transparent transparent  transparent;
            }
        }        
    }

    &.SuspendedEVSE.charger,
    &.SuspendedEV.charger,
    &.Reserved.charger,
    &.Unavailable.charger,
    &.Faulted.charger {
        .fw-map-overlay-textbox {
            background-color: $marker-s4-color;
            &::after {
                border-color: $marker-s4-color transparent transparent  transparent;
            }
        }        
        .fw-map-overlay-marker {
            .marker-icon svg rect {
                fill: $marker-s4-color; // EVSE 정지, EV 정지, 예약, 이용불가, 오류발생
            }
            &::after {
                border-color: $marker-s4-color transparent transparent  transparent;
            }
        }         
    }
}